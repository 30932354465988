export const nftAddress = {
    1: "0x3578FA2F3DD8f8Faf49F173b50e4853Aabf26641",
    2: "0xa08893B15D272441C1cEF7B177Adbfc46ec6888C",
    3: "0x95ecFf0909b7b0A582773300f22d25dab2a1BD00",
    4: "0xA38abD8509E6a330c15E03178f0734FbAb138E7c",
    5: "0xea15915F0Ba93f282e79dD087CaF91F2ed2A89F8",
    6: "0xB2D62c9fA7CE579dFFF0E4042a2B25d599F108A2",
    7: "0x5476CA03D96d8b711368aFd14835a73BF1b2B41c",
    8: "0xa8d972BA284B216732b9667fc7772cd993954728",
    9: "0x50859B648BF1Cf4eF187915D1fB25eABC404dFD5",
    10: "0x5A94e0E223f304F332cd01aADB1B03268b282Ba7",
    11: "0x4aD5D737c7d12769B528D4DfEa62CF4cAF490A75",
    12: "0xf93Ac31014e8B57627cDb1A49c5fd950d3A9bb98",
    13: "0xf2688fC91b26d6e75A1B3A65c521D9B1d7657622",
    14: "0x0645ee6fBfAC7060d593D59096b53205674e979e",
    15: "0xD9822E8D7F415A025dbaE0762F981326bD58fB0E"
}