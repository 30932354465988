import React, { useEffect, useState } from 'react'
import logofav from '../../Assets/images/iconlogo.webp'
import tether from '../../Assets/images/tether.png'
import ocicon from '../../Assets/images/iconlogo.webp'
import metaMaskicon from '../../Assets/images/metaMask-icon.svg'
import WalletConnectIcon from '../../Assets/images/walletconnect-icon.svg'
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom'
import { ShoppingCart } from '@material-ui/icons';
import { ArrowUpward } from '@mui/icons-material';


import { IKImage } from 'imagekitio-react';
import { useOcPrice } from '../../hooks/useOcPrice'
import { names } from '../../config'
import PlaceholderImage from "../../Assets/images/loading.gif";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Slot = (props) => {

    const ids = props.ids;
    const index = props.index;
    const levelPrices = props.levelPrices;
    const reinvestCounts = props.reinvestCounts;
    const cachedAmount = props.cachedAmount
    const levelBought = props.levelBought

    const [ocAmount, setOcAmount] = useState(0)
    const ocPrice = props.ocPrice
    useEffect(() => {
        if (ocPrice) {
            let _ocAmount = (levelPrices && levelPrices[index]) / ocPrice
            setOcAmount(_ocAmount)
        }
    }, [setOcAmount, ocPrice, levelPrices, index])


    const getIdClass = (id) => {
        return id == 0 ? "dark" : "";
    };

    return props.isActive ?
        <>
            <div className="col-xl-6 col-lg-6 col-sm-12 col-12 mb-4">
                <a onClick={() => props.onSlotClick(ids[0], index + 1)}>
                    <div className="treebox">
                        <div className="row">
                            <div className="col-7">
                                <div className='row mb-4'>
                                    <div className='col-6'>+ {props.index + 1} <span className='sbdb'>({names[index]})</span></div>
                                    <div className="col-6 headeline"><span><img src={tether} alt="USDT Logo" width="20" height="20" />{parseFloat(ocAmount).toFixed(2)} <span className='sbdb text-end'>(${levelPrices && levelPrices[index]})</span></span></div>
                                </div>
                                <div className="row my-3">{/* downSticky */}
                                    <div className="col-12 row footerBox">
                                        <div className="footerline">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon" role="img">
                                                <path fill="var(--ci-primary-color, currentColor)" d="M265.614,206.387H456V16H424V149.887L397.863,123.75c-79.539-79.539-208.96-79.54-288.5,0s-79.539,208.96,0,288.5a204.232,204.232,0,0,0,288.5,0l-22.627-22.627c-67.063,67.063-176.182,67.063-243.244,0s-67.063-176.183,0-243.246,176.182-67.063,243.245,0l28.01,28.01H265.614Z" className="ci-primary"></path>
                                            </svg>
                                            <span>{reinvestCounts ? reinvestCounts[index] : 0}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="itemsmain">
                                    <div className="col6">
                                        <div className="col12"><span title={ids[1]} className={getIdClass(ids[1])}></span></div>
                                        <div className="col6">
                                            <div className="col12"><span title={ids[3]} className={getIdClass(ids[3])}></span></div>
                                            <div className="col6">
                                                <div className="col12"><span title={ids[7]} className={getIdClass(ids[7])}></span></div>
                                                <div className="col6"><span title={ids[15]} className={getIdClass(ids[15])}></span></div>
                                                <div className="col6"><span title={ids[16]} className={getIdClass(ids[16])}></span></div>
                                            </div>
                                            <div className="col6">
                                                <div className="col12"><span title={ids[8]} className={getIdClass(ids[8])}></span></div>
                                                <div className="col6"><span title={ids[17]} className={getIdClass(ids[17])}></span></div>
                                                <div className="col6"><span title={ids[18]} className={getIdClass(ids[18])}></span></div>
                                            </div>
                                        </div>
                                        <div className="col6">
                                            <div className="col12"><span title={ids[4]} className={getIdClass(ids[4])}></span></div>
                                            <div className="col6">
                                                <div className="col12"><span title={ids[9]} className={getIdClass(ids[9])}></span></div>
                                                <div className="col6"><span title={ids[19]} className={getIdClass(ids[19])}></span></div>
                                                <div className="col6"><span title={ids[20]} className={getIdClass(ids[20])}></span></div>
                                            </div>
                                            <div className="col6">
                                                <div className="col12"><span title={ids[10]} className={getIdClass(ids[10])}></span></div>
                                                <div className="col6"><span title={ids[21]} className={getIdClass(ids[21])}></span></div>
                                                <div className="col6"><span title={ids[22]} className={getIdClass(ids[22])}></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col6">
                                        <div className="col12"><span title={ids[2]} className={getIdClass(ids[2])}></span></div>
                                        <div className="col6">
                                            <div className="col12"><span title={ids[5]} className={getIdClass(ids[5])}></span></div>
                                            <div className="col6">
                                                <div className="col12"><span title={ids[11]} className={getIdClass(ids[11])}></span></div>
                                                <div className="col6"><span title={ids[23]} className={getIdClass(ids[23])}></span></div>
                                                <div className="col6"><span title={ids[24]} className={getIdClass(ids[24])}></span></div>
                                            </div>
                                            <div className="col6">
                                                <div className="col12"><span title={ids[12]} className={getIdClass(ids[12])}></span></div>
                                                <div className="col6"><span title={ids[25]} className={getIdClass(ids[25])}></span></div>
                                                <div className="col6"><span title={ids[26]} className={getIdClass(ids[26])}></span></div>
                                            </div>
                                        </div>
                                        <div className="col6">
                                            <div className="col12"><span title={ids[6]} className={getIdClass(ids[6])}></span></div>
                                            <div className="col6">
                                                <div className="col12"><span title={ids[13]} className={getIdClass(ids[13])}></span></div>
                                                <div className="col6"><span title={ids[27]} className={getIdClass(ids[27])}></span></div>
                                                <div className="col6"><span title={ids[28]} className={getIdClass(ids[28])}></span></div>
                                            </div>
                                            <div className="col6">
                                                <div className="col12"><span title={ids[14]} className={getIdClass(ids[14])}></span></div>
                                                <div className="col6"><span title={ids[29]} className={getIdClass(ids[29])}></span></div>
                                                <div className="col6"><span title={ids[30]} className={getIdClass(ids[30])}></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="imgaeboxNft">
                                    {/* <h2>NFT Image</h2> */}
                                    <div className="imgBxnft">
                                        <LazyLoadImage src={require(`../../Assets/images/nfts/nft${props.index + 1}.gif`)}
                                            width={250} height={250}
                                            alt="NFT Image"
                                            PlaceholderSrc={''}
                                            effect="blur"
                                        />
                                        {/* <img src={require(`../../Assets/images/nfts/nft${props.index + 1}.gif`)} alt="NFT Logo" width={250} height={250} /> */}
                                        {/* <IKImage urlEndpoint={urlEndpoint} path={require(`../../Assets/images/nfts/nft${props.index + 1}.gif`)} alt="NFT Logo" width={250} height={250} /> */}
                                        {/* <IKImage
                                    urlEndpoint={urlEndpoint}
                                    path={`nft${props.index + 1}.gif`}
                                    lqip={{ active: true }}
                                    transformation={[{
                                        height: 295,
                                        width: 295,
                                        crop: 'force',
                                    }]}
                                    loading="lazy"
                                    alt="NFT Image"
                                /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </>
        :
        <>

            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                <div className="treebox">
                    <div className="row">
                        <div className="col-7">
                            <div className="row mb-4">
                                <div className="col-6">Slot {props.index + 1} <span className='sbdb'>({names[index]})</span></div>
                                <div className="col-6">
                                    <div className="headeline">
                                        <span><img src={tether} alt="My profile Logo" width="20" height="20" />
                                            {
                                                ((levelBought + 1) === (props.index + 1) && cachedAmount !== 0) ? parseFloat(ocAmount - cachedAmount < 0 ? 0 : ocAmount - cachedAmount).toFixed(2) : parseFloat(ocAmount).toFixed(2)
                                            }
                                            <span className='sbdb text-end'></span>
                                            {(levelBought + 1) === (props.index + 1) && cachedAmount !== 0 ? <span style={{ textDecoration: "line-through" }}>{levelPrices && levelPrices[index]} USDT</span> : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='cartBox'>
                                {/* {props.index == 2 || props.index == 4 || props.index == 6 ? <a onClick={() => props.onBuyLevel(index + 1, true)}><button><ShoppingCart></ShoppingCart></button><p>Pay with ATX</p></a> : ''} */}
                                <a onClick={() => props.onBuyLevel(index + 1)}><button className='am_btn'><ArrowUpward></ArrowUpward><i></i><i></i><span></span><b>Upgrade</b></button></a>
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="imgaeboxNft">
                                {/* <h2>NFT Image</h2> */}
                                <div className="imgBxnft">
                                    <LazyLoadImage src={require(`../../Assets/images/nfts/nft${props.index + 1}.gif`)}
                                        width={250} height={250}
                                        alt="NFT Image"
                                        PlaceholderSrc={''}
                                        effect="blur"
                                    />

                                    {/* <img src={require(`../../Assets/images/nfts/nft${props.index + 1}.gif`)} alt="NFT Logo" width={250} height={250} /> */}
                                    {/* <img src={require(`../../Assets/images/nfts/nft${props.index + 1}.gif`)} alt="NFT Logo" width={250} height={250} /> */}
                                    {/* <IKImage
                                urlEndpoint={urlEndpoint}
                                path={`nft${props.index + 1}.gif`}
                                lqip={{ active: true }}
                                transformation={[{
                                    height: 295,
                                    width: 295,
                                    crop: 'force',
                                }]}
                                loading="lazy"
                                alt="NFT Image"
                            /> */}
                                </div>
                                {/* <span className='upgradeView'>Upgrade to view</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        ;
}

export default Slot