import React, { useCallback, useState } from 'react'
import logo from '../../Assets/images/logo.gif'
import oclogo from '../../Assets/images/iconlogo.webp'
import './header.css'
import { Dropdown } from 'react-dropdown-now';
import 'react-dropdown-now/style.css';
import ConnectWallet from '../ConnectWallet/ConnectWallet';
import useAuth from '../../hooks/useAuth';
import { ConnectorNames } from '../../utils/web3React';
import useActiveWeb3React from '../../hooks/useWeb3';
import useDirectConnect from '../../hooks/useDirectConnect';
import { usePollBlockNumber } from '../../state/block/hooks';
import { useNavigate } from 'react-router';

const Header = (props) => {
    const [buttonPopup, setButtonPopup] = useState(false);
    const { account } = useActiveWeb3React()
    const { login, logout } = useAuth()

    const connect = useCallback((connector) => {
        login(connector)
        setButtonPopup(false);
    })
    const navigate = useNavigate();
    const disconnectWallet = async () => {
        logout();
        navigate("/");
    }

    useDirectConnect()
    usePollBlockNumber()

    const options = [
        { label: 'Disconnect', value: 'logout' },
    ];
    return (
        <>
            <header>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6 col-md-6 text-start">
                            <div className="logo">
                                <a href="/"><img src={logo} alt="logo" width="120" height="90" /></a>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 text-end">
                            <div className='rightboxmenu'>
                                {/* Before Login */}

                                {account ? <>
                                    <Dropdown placeholder={account ? (account.substr(0, 4) + '...' + account.substr(-4)) : "Connect Wallet"} options={options} onChange={disconnectWallet} />
                                    <img src={oclogo} className="profLogo" alt="oclogo" width="38" height="38" /></>
                                    : <ul className='list-unstyled m-0'>
                                        <li>
                                            <button onClick={() => account ? console.log('already connected') : setButtonPopup(true)} className='am_btn cnctwlthedBtn' data-coreui-toggle="modal" href="#connectWalletToggle"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon" role="img"><path fill="var(--ci-primary-color, currentColor)" d="M489.972,119.059a23.839,23.839,0,0,0-17-7.059H456V72a24.027,24.027,0,0,0-24-24H86.627A70.628,70.628,0,0,0,16,118.627V393.373A70.628,70.628,0,0,0,86.627,464h385.4a24.047,24.047,0,0,0,24-23.923l.944-303.995A23.837,23.837,0,0,0,489.972,119.059ZM464.053,432H86.627A38.627,38.627,0,0,1,48,393.373V118.627A38.627,38.627,0,0,1,86.627,80H424v32H88v32H464.947Z" className="ci-primary"></path><rect width="32" height="32" x="392" y="264" fill="var(--ci-primary-color, currentColor)" className="ci-primary"></rect></svg><i></i><i></i><span>Connect Wallet</span></button>
                                        </li>
                                    </ul>}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ConnectWallet props={props} onTrustWalletClick={() => connect(ConnectorNames.Injected)} onMetamaskClick={() => connect(ConnectorNames.Injected)} onWalletConnectClick={() => connect(ConnectorNames.WalletConnect)} trigger={buttonPopup} setTrigger={setButtonPopup} />
        </>
    )
}

export default Header