import React from 'react'
import { CRow, CCol, CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react';
import '../../Components/FaqOuter/FaqOuter.css'
import faqAssets from '../../Assets/images/faqAssets.webp'
const FaqOuter = () => {
    return (
        <>
            <section className="faqOuterSection mt-4 loginRegisterBox">
                <div className='container rotateGradientAb'>
                    <div className='titleBox'>
                        <h2>FAQ's</h2>
                    </div>
                    <CRow>
                        <CCol xs={12} md={12} lg={4} xl={4}>
                            <div className="rowtreebox" id='SlotLevelDetails'>
                                <CAccordion activeItemKey={0}>
                                    <CAccordionItem>
                                        <CAccordionHeader className='treebox'>
                                            <i></i><i></i><span></span>
                                            <b>What is ApeCommunity NFT?</b>
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <p>ApeCommunity NFT is the international community of the global decentralized ecosystem and the first ever polygon smart contract marketing matrix. Please note, here when you pay to the platform, you are paying to buy nfts, while the matrix plan is an add on which is free to join once you purchase an nft. Once you have purchased an nft you are automatically a part of self-executing software algorithm that performs the function of distributing partner rewards between community members, subject to certain conditions (matrix marketing plan).</p>
                                            <p>The contract code is publicly available. Information about transactions can always be viewed at the link <a href="https://polygonscan.com/" target="_blank" rel="noreferrer">polygonscan.com</a>. Once you have purchased an nft, you are the owner of the nft & you can find that nft in your dapp wallet.</p>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                    <CAccordionItem>
                                        <CAccordionHeader className='treebox'>
                                            <i></i><i></i><span></span>
                                            <b>Who Manages The Platform?</b>
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <p>The ApeCommunity NFT platform consists of self-executing trades, which do not permit anyone to interfere with the course of the transactions.</p>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                    <CAccordionItem>
                                        <CAccordionHeader className='treebox'>
                                            <i></i><i></i><span></span>
                                            <b>Who Created ApeCommunity NFT?</b>
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <p>The ApeCommunity NFT concept belongs to a group of crypto enthusiasts, who are members of the community and don’t have any special privileges. Today, ApeCommunity NFT is an peer-to-peer community of platform members, to whom the platform itself belongs.</p>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                    <CAccordionItem>
                                        <CAccordionHeader className='treebox'>
                                            <i></i><i></i><span></span>
                                            <b>What will happen to my account if i take a break from working with the ApeCommunity NFT community?</b>
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <p>No one can close your account, even if they have a strong desire to do so. The account will always be saved in one of the Polygon network blocks. You will continue to receive income from all levels, except the last active one.</p>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                </CAccordion>
                            </div>
                        </CCol>
                        <CCol xs={12} md={12} lg={4} xl={4}>
                            <div className='faqAssetsBox'>
                                <img src={faqAssets} width="400" height="470" alt="faqAssets" />
                            </div>
                        </CCol>
                        <CCol xs={12} md={12} lg={4} xl={4}>
                            <div className="rowtreebox" id='SlotLevelDetails'>
                                <CAccordion activeItemKey={0}>
                                    <CAccordionItem>
                                        <CAccordionHeader className='treebox'>
                                            <i></i><i></i><span></span>
                                            <b>Which wallet should I use?</b>
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <p>ApeCommunity NFT works on any wallet that supports Polygon Chain. However you may use, Trust Wallet, Metamask or TokenPocket Wallet for best experience.</p>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                    <CAccordionItem>
                                        <CAccordionHeader className='treebox'>
                                            <i></i><i></i><span></span>
                                            <b>How to Buy an NFT if I have never done  it before?</b>
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <p>Buying an NFT is as easy as making a bread butter, you just have to open the website in a DApp Wallet, go to register page, click on Approve USDT, and once USDT is approved, you have to click on register button. Once clicked, your Dapp Wallet will ask you to approve the transaction. Once approved, Bingo! You have bought your Ape Community NFT.</p>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                    <CAccordionItem>
                                        <CAccordionHeader className='treebox'>
                                            <i></i><i></i><span></span>
                                            <b>Can I register without a partner link?</b>
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <p>Yes. Registering without an invitation link will put you in team.</p>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                    <CAccordionItem>
                                        <CAccordionHeader className='treebox'>
                                            <i></i><i></i><span></span>
                                            <b>What is an NFT ?</b>
                                        </CAccordionHeader>
                                        <CAccordionBody>
                                            <p>NFT stands for non-fungible token, which is a type of digital asset that is verified using blockchain technology. Non-fungible means that the asset is unique and cannot be replaced by something else of equal value, unlike fungible assets like fiat currency or gold bars.</p>
                                        </CAccordionBody>
                                    </CAccordionItem>
                                </CAccordion>
                            </div>
                        </CCol>
                    </CRow>

                </div>
            </section>
        </>
    )
}

export default FaqOuter