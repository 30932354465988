import React, { useCallback, useEffect, useState } from 'react';
import './Login.css'
import logofav from '../../Assets/images/iconlogo.webp'
import useActiveWeb3React from '../../hooks/useWeb3';
import { useSelector } from 'react-redux';
import { OxNFTContract, atxContract, BASE_URL, defaultNetwork, usdtContract, chainRPC } from '../../config';
import { useStakeContract, useTokenContract } from "../../hooks/useContract";
import { getPlanContract } from '../../services/planContract';
import { useAccountInfo } from '../../state/account/hooks';
import { ethers } from 'ethers';
import getLast10TransactionsAndEvents from '../../services/activities';
import { useNavigate } from 'react-router';
import toast, { Toaster } from 'react-hot-toast';
import Banner from '../../Components/Banner/Banner';
import fetchIncomeLast24h from '../../services/incomeLast24h';
import fetchMembersLast24h from '../../services/membersLast24h'; import { Link } from 'react-router-dom';
import { registerToken } from '../../utils/setupNetwork';
import CIcon from '@coreui/icons-react'
import { cilContact } from '@coreui/icons'
import { cilMeh } from '@coreui/icons'
import { cilBaby } from '@coreui/icons'
import { cilSnowflake } from '@coreui/icons'
import { CChart } from '@coreui/react-chartjs'
import income24Icon from '../../Assets/images/icons/income24-icon.webp'
import incomeIcon from '../../Assets/images/icons/income-icon.webp'
import partnersIcon from '../../Assets/images/icons/partners-icon.webp'
import userIcon from '../../Assets/images/icons/user-icon.webp'
import sliderIcon1 from '../../Assets/images/iconlogo.webp'
import sliderIcon2 from '../../Assets/images/textlogo.webp'
import sliderIcon3 from '../../Assets/images/logo.gif'
import bannermain from '../../Assets/images/bannermain.jpg'
import contractsimg from '../../Assets/images/contractsimg.webp'
import {
    CRow,
    CCol,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableRow,
    CAvatar,
} from '@coreui/react'
import FaqOuter from '../../Components/FaqOuter/FaqOuter';
import Marquee from "react-fast-marquee";
// import FormDialog from '../../Components/Popup/Popupsec';
const Loader = () => {
    return (
        <div className="loader"></div>
    )
}

const Login = () => {

    const { library, chainId, account } = useActiveWeb3React()
    const [isInTrn, setTransaction] = useState(false);
    const [totalMembers, setTotalMembers] = useState(0);
    const [yesterdayMembers, setYesterdayMembers] = useState(0);
    const [yesterdayIncome, setYesterdayIncome] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [activity, setActivity] = useState([]);
    const [income24, setIncome24] = useState([]);
    const [members24, setMembers24] = useState([]);
    const planC = useStakeContract();

    useEffect(() => {
        if (planC) {
            fetchPanelDetails();
        }

    }, [planC])

    const fetchPanelDetails = async () => {
        let actDiv = document.getElementById('activitiesView');
        actDiv.classList.add('loaderData');
        let tMs = await planC.lastIDCount();
        setTotalMembers(ethers.BigNumber.from(tMs).toNumber());
        let totalInvestedBN = await planC.totalValueInvested();
        let allTotalValue = ethers.utils.formatEther(totalInvestedBN)
        setTotalInvested(allTotalValue);

        const startLimit = Math.floor(Date.now() / 1000)
        const oneDayInSeconds = 24 * 60 * 60;
        const endLimit = parseInt(startLimit) - oneDayInSeconds;

        let getIncome24 = await fetchIncomeLast24h();
        setYesterdayIncome(getIncome24)

        let getMembers24 = await fetchMembersLast24h([], startLimit, endLimit);
        setYesterdayMembers(getMembers24)

        let getActivity = await getLast10TransactionsAndEvents(library);
        setActivity(getActivity)
        console.debug("getActivity", getActivity.length)
        actDiv.classList.remove('loaderData');
    }

    const navigate = useNavigate();
    const makeAutoLogin = useCallback(async () => {
        if (!account) {
            toast.error("Connect wallet!")
            return
        }
        try {
            const userInfo = await planC.userInfos(account)
            // console.log(userInfo)
            if (userInfo.joined) {
                navigate("/dashboard?user_id=" + parseInt(userInfo.id))
            } else {
                navigate("/register")
            }
        } catch (Err) {
            console.log(Err)
        }
    }, [account, planC])

    const loginAsViewer = async () => {
        let userId = document.getElementById('exampleViewId').value;
        const isInputEmpty = !userId.trim();

        if (!isInputEmpty && userId > 0) {
            navigate("/dashboard?user_id=" + parseInt(userId))
            // toast.success('Login Successfully!')
        } else {
            toast.error("Please enter user id to view")
        }
    }

    const addBscTestnetToMetaMask = async () => {
        if (typeof window.ethereum === 'undefined') {
            toast.error('MetaMask is not installed. Please install MetaMask and try again.');
            return;
        }

        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: '0x89', // BSC Testnet chainId in hexadecimal
                        chainName: 'Polygon',
                        nativeCurrency: {
                            name: 'MATIC',
                            symbol: 'MATIC',
                            decimals: 18,
                        },
                        rpcUrls: [chainRPC],
                        blockExplorerUrls: ['https://polygonscan.com/'],
                    },
                ],
            });
        } catch (error) {
            console.error(error);
            toast.error('Error adding Polygon to MetaMask: ' + error.message);
        }
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <>
            <Banner />
            <section className='loginMian'>
                <div className='container'>
                    <CRow>
                        <CCol xs={12} md={12} lg={12} xl={12} className='pe-4 padingR-0-mob order-3 order-lg-0'>
                            {/* <div className="titleBox">
                                <h2>Recent Activities</h2>
                            </div> */}
                            <div className='row'>
                                <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                    <div className='inrBox odd'>
                                        <i></i><i></i><span></span>
                                        <img src={userIcon} alt="User icon" width="70" height="70" />
                                        <h3>{yesterdayMembers}</h3>
                                        <p>Members in last 24 hours</p>
                                        {/* <CIcon icon={cilContact} /> */}
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                    <div className='inrBox even'>
                                        <i></i><i></i><span></span>
                                        <img src={income24Icon} alt="Income 24" width="70" height="70" />
                                        <h3>{yesterdayIncome}</h3>
                                        <p>Income in last 24 hours, USD</p>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                    <div className='inrBox odd'>
                                        <i></i><i></i><span></span>
                                        <img src={partnersIcon} alt="partners icon" width="70" height="70" />
                                        <h3>{totalMembers}</h3>
                                        <p>Total Members</p>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                    <div className='inrBox even'>
                                        <i></i><i></i><span></span>
                                        <img src={incomeIcon} alt="Income" width="70" height="70" />
                                        <h3>{totalInvested}</h3>
                                        <p>Total USD Invested</p>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </div>
            </section>
            <section className='loginRegisterBox'>
                <div className="container rotateGradientAb">
                    <div className="logRegInner">
                        <div className="boxIn1"></div>
                        <div className="boxIn2"></div>
                        <div className="boxIn3"></div>
                        <button className='buttonBox btn1 am_btn' onClick={makeAutoLogin}><i></i><i></i><span>Auto-Login</span></button>
                        <Link className='buttonBox btn2' to={"/register"}>
                            <button className='am_btn' onClick={scrollToTop}><i></i><i></i><span>Register</span></button>
                        </Link>
                        <button onClick={() => addBscTestnetToMetaMask()} className='buttonBox btn3 am_btn'><i></i><i></i><span>Add Mainnet</span></button >
                        <button onClick={() => registerToken(usdtContract, "USDT", 18, "https://polygonscan.com/token/images/tether_32.png")} className='buttonBox btn4 am_btn'><i></i><i></i><span>Add USDT</span></button >
                    </div>
                </div>
            </section>
            <section className='loginRegisterBox rotateGradientAb'>
                <div className='imgSlidersLr'>
                    <Marquee direction={"left"} className='imgSlidersL'>
                        <img src={sliderIcon1} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon2} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon3} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon1} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon2} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon3} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon1} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon2} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon3} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon1} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon2} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon3} width={50} height={50} alt="slider icons" />
                    </Marquee>
                    <Marquee direction={"right"} className='imgSlidersL'>
                        <img src={sliderIcon2} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon3} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon1} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon2} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon3} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon1} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon2} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon3} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon1} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon2} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon3} width={50} height={50} alt="slider icons" />
                        <img src={sliderIcon1} width={50} height={50} alt="slider icons" />
                    </Marquee>
                </div>
            </section>
            <section className='loginMian rotateGradient'>
                <div className='container'>
                    <CRow>
                        <CCol xs={12} md={12} lg={6} xl={6} className="accountPreviewbtm">
                            <div className='accountPreviewMain'>
                                <CRow className='tablemainnet rightBox'>
                                    <CCol xs={12} md={12} className='order-1 order-md-0'>
                                        <div className='mainnetInr'>
                                            <div className='tablemainnet'>
                                                <div className="titleBox">
                                                    <h2>ApeCommunity Contracts</h2>
                                                </div>
                                                <div className='mainnetInr setminheight'>
                                                    <div className='row linkBox'>
                                                        <strong>ApeCommunity NFT</strong><span>{OxNFTContract.substr(0, 4)}...{OxNFTContract.substr(-4)}
                                                            <a href={"https://polygonscan.com/address/" + OxNFTContract} target="_blank"><svg className="h-18px w-18px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.314 3.813a3.446 3.446 0 1 1 4.873 4.874l-3.331 3.331a3.418 3.418 0 0 1-4.88-.001.5.5 0 0 1 .715-.7 2.417 2.417 0 0 0 3.452 0l.004-.004L15.48 7.98a2.446 2.446 0 1 0-3.46-3.46l-.416.417a.5.5 0 1 1-.707-.707l.417-.417Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.26 7.225a3.417 3.417 0 0 1 3.765.758.5.5 0 0 1-.715.7 2.417 2.417 0 0 0-3.452 0l-.003.004L4.52 12.02a2.446 2.446 0 0 0 3.46 3.46l.416-.417a.5.5 0 1 1 .708.707l-.417.417a3.446 3.446 0 1 1-4.874-4.874l3.332-3.332a3.417 3.417 0 0 1 1.115-.756Z" fill="#fff"></path></svg></a>
                                                        </span>
                                                    </div>
                                                    <div className='row linkBox'>
                                                        <strong>USDT</strong><span>{usdtContract.substr(0, 4)}...{usdtContract.substr(-4)}
                                                            <a href={"https://polygonscan.com/address/" + usdtContract} target="_blank"><svg className="h-18px w-18px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.314 3.813a3.446 3.446 0 1 1 4.873 4.874l-3.331 3.331a3.418 3.418 0 0 1-4.88-.001.5.5 0 0 1 .715-.7 2.417 2.417 0 0 0 3.452 0l.004-.004L15.48 7.98a2.446 2.446 0 1 0-3.46-3.46l-.416.417a.5.5 0 1 1-.707-.707l.417-.417Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.26 7.225a3.417 3.417 0 0 1 3.765.758.5.5 0 0 1-.715.7 2.417 2.417 0 0 0-3.452 0l-.003.004L4.52 12.02a2.446 2.446 0 0 0 3.46 3.46l.416-.417a.5.5 0 1 1 .708.707l-.417.417a3.446 3.446 0 1 1-4.874-4.874l3.332-3.332a3.417 3.417 0 0 1 1.115-.756Z" fill="#fff"></path></svg></a>
                                                        </span>
                                                    </div>
                                                    <div className='row linkBoxBtm'>
                                                        <p>Turnover, USD</p><span>{totalInvested}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        </CCol>
                        <CCol xs={12} md={12} lg={1} xl={1}></CCol>
                        <CCol xs={12} md={12} lg={5} xl={5} className="accountPreviewbtm">
                            <div className='accountPreviewMain'>
                                <CRow className='tablemainnet rightBox'>
                                    <CCol xs={12} md={12} className='order-1 order-md-0'>
                                        <div className='mainnetInr'>
                                            <div className='titleBox'>
                                                <h2>Account preview</h2>
                                                <p className='max-w[300px]'>Look up any ApeCommunity NFT member account in preview mode. Enter ID to preview.</p>
                                            </div>
                                            {/* <h3>Enter ID</h3> */}
                                            <div className='row inputBox am_btn'>
                                                <i></i><i></i><span></span>
                                                <input type="number" id='exampleViewId' min="1" placeholder='Enter Id' />
                                                <button className='am_btn' onClick={() => { loginAsViewer(); scrollToTop(); }}>View</button>
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        </CCol>
                    </CRow>
                </div>
            </section>
            <section className='loginMian recentActivities rotateGradient'>
                <div className='container'>
                    <div className='accountPreviewMain'>
                        <div className='tablemainnet'>
                            <div className="titleBox">
                                <h2>Recent Activities</h2>
                            </div>
                            <div className='tableInr' id='activitiesView'>
                                <div className='table-responsive'>
                                    <table className="table table-striped-columns">
                                        {/* <thead>
                                                    <tr>
                                                        <th width="10%"></th>
                                                        <th width="20%"></th>
                                                        <th width="35%"></th>
                                                        <th width="35%"></th>
                                                    </tr>
                                                </thead> */}
                                        <tbody>
                                            {activity.map((a, index) => {
                                                return (a.eventsName == "Registration" ? <tr key={index}>
                                                    <td><img src={logofav} alt="ETH" width="20" height="20" /><a href="#!">ID {parseInt(a.userId)}</a></td>
                                                    <td className='txtinf'>NEW USER <p>JOINED</p></td>
                                                    <td className='linkshr text-end'>
                                                        <a href={"https://polygonscan.com/tx/" + a.txId} target="blank"><svg className="w-6 h-6" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"></path></svg></a>
                                                        <span>{a.date}</span></td>
                                                </tr> : <tr key={index}>
                                                    <td><img src={logofav} alt="ETH" width="20" height="20" /><a href="#!">ID {parseInt(a.userId)}</a></td>
                                                    <td className='txtinf'><span>+</span>{ethers.utils.formatEther(a.amount)} USD<span>in</span><p>USDT</p></td>
                                                    <td className='linkshr text-end'>
                                                        <a href={"https://polygonscan.com/tx/" + a.txId} target="blank"><svg className="w-6 h-6" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"></path></svg></a>
                                                        <span>{a.date}</span></td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FaqOuter />
            {/* <FormDialog /> */}
        </>
    )
}

export default Login