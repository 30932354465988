import React, { useCallback, useEffect, useState } from 'react'
import './Dashboard.css'
import logofav from '../../Assets/images/iconlogo.webp'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import { ShoppingCart } from '@material-ui/icons';
import useActiveWeb3React from '../../hooks/useWeb3';
import { useContract, useStakeContract, useTokenContract } from '../../hooks/useContract';
import { ethers } from 'ethers';
import getLast10TransactionsAndEvents from '../../services/activities';
import { useLocation, useNavigate } from 'react-router';
import { OxNFTContract, atxContract, levelPrices, swapAddress, usdtContract, BASE_BSC_SCAN_URLS, names, BASE_URL, profileURL, profileImage } from '../../config';
import Slot from '../../Components/Slot/Slot';
import Footer from '../../Components/Footer/Footer';
import copyIcon from '../../Assets/images/unlock-thailand.webp'
import unlockThailand from '../../Assets/images/unlock-thailand.webp'
import doubleMagic from '../../Assets/images/double-magic.webp'
import { Link } from '@material-ui/core';
import { calculateTotalInvested } from '../../utils';
import fetchUserIncome from '../../services/userIncome';
import CIcon from '@coreui/icons-react'
import { cilContact } from '@coreui/icons'
import { cilMeh } from '@coreui/icons'
import { cilBaby } from '@coreui/icons'
import { cilSnowflake } from '@coreui/icons'
import tether from '../../Assets/images/tether.png'
import ratioIcon from '../../Assets/images/icons/ratio-icon.webp'
import incomeIcon from '../../Assets/images/icons/income-icon.webp'
import partnersIcon from '../../Assets/images/icons/partners-icon.webp'
import unlockedIcon from '../../Assets/images/icons/unlocked-icon.webp'
// import { useOxProfile } from '../../hooks/useOxProfile';
import { useIsSigned, useSignMessage } from '../../hooks/useIsSigned';
import { useStake } from '../../state/account/hooks';
import { useOcPrice } from '../../hooks/useOcPrice';

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import { Tooltip as BSTooltip } from 'bootstrap';
import getAchivementTransactions from '../../services/achievement';

const copySuccessfully = () => toast.success('Copied Successfully!');

function Dashboard() {

    const { library, chainId, account } = useActiveWeb3React()
    const [isInTrn, setTransaction] = useState(false);
    const [totalMembers, setTotalMembers] = useState(0);
    const [userId, setUserId] = useState(0);
    const [yesterdayMembers, setYesterdayMembers] = useState(0);
    const [yesterdayIncome, setYesterdayIncome] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [allInvested, setAllInvested] = useState(0);
    const [profitRatio, setProfitRatio] = useState(0);
    const [activity, setActivity] = useState([]);
    const [achievementThailand, setAchievementThailand] = useState([]);
    const [achievementMagic, setAchievementMagic] = useState([]);
    const [achievementAmount, setAchievementAmount] = useState(0);

    const { approveUSDT: approveOC } = useStake()

    const [userAddress, setUserAddress] = useState('');
    const [userTotalIncome, setUserTotalIncome] = useState(0);
    const [levelBought, setLevelBought] = useState(0);
    const [partners, setPartners] = useState(0);
    const [directIncome, setDirectIncome] = useState(0);
    const [levelIncome, setLevelIncome] = useState(0);
    const [myTeamTotal, setMyTeamTotal] = useState(0);
    const [cachedAmount, setCachedAmount] = useState(0);
    const treeNodes = 31;

    const totalLevels = 15;
    let tempidsArray = new Array(totalLevels).fill(0).map(() => new Array(treeNodes).fill(0));
    const [idsArray, setIdsArray] = useState(tempidsArray);

    const [reinvestCounts, setReinvestCounts] = useState(Array(15).fill(0));

    const planC = useStakeContract();
    // const { data } = useOxProfile({ userID: userId })
    const location = useLocation();

    const { isSigned } = useIsSigned({ userAddress: userAddress })

    const usdtInstance = useTokenContract(usdtContract);
    const ocPrice = 1;


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
    }, [setUserId, location])

    useEffect(() => {
        if (planC && userId !== 0) {
            fetchPanelDetails();
            fetchIncome();
        }
    }, [userId, planC])

    // useEffect(() => {
    //     const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    //     tooltipTriggerList.forEach(function (tooltipTriggerEl) {
    //         return new BSTooltip(tooltipTriggerEl, {
    //             placement: 'bottom'
    //         });
    //     });
    // }, []);

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.forEach(function (tooltipTriggerEl) {
            const options = {
                html: true, // Enable HTML content
                title: `<ul class="list-unstyled mb-0">
                      <li>The second cycle funds will only be on hold for your next slot purchase.</li>
                      <li>Only the user can upgrade an account.</li>
                    </ul>`, // Your HTML content
                placement: 'bottom', // Tooltip position
            };
            return new BSTooltip(tooltipTriggerEl, options);
        });
    }, []);

    useEffect(() => {
        const tooltipTriggerList1 = [].slice.call(document.querySelectorAll('[data-bs-toggle1="tooltip"]'));
        tooltipTriggerList1.forEach(function (tooltipTriggerEl1) {
            const options = {
                html: true, // Enable HTML content
                title: `<ul class="list-unstyled mb-0">
                      <li>Unlock Thailand :</li>
                      <li>Make your move! Direct Income $2,500 and secure 1 Ticket to Thailand</li>
                    </ul>`, // Your HTML content
                placement: 'bottom', // Tooltip position
            };
            return new BSTooltip(tooltipTriggerEl1, options);
        });
    }, []);

    useEffect(() => {
        const tooltipTriggerList2 = [].slice.call(document.querySelectorAll('[data-bs-toggle2="tooltip"]'));
        tooltipTriggerList2.forEach(function (tooltipTriggerEl2) {
            const options = {
                html: true, // Enable HTML content
                title: `<ul class="list-unstyled mb-0">
                      <li>Double the Magic :</li>
                      <li>Elevate your experience! With a $4,500 Direct Income, grab 2 Tickets to paradise</li>
                    </ul>`, // Your HTML content
                placement: 'bottom', // Tooltip position
            };
            return new BSTooltip(tooltipTriggerEl2, options);
        });
    }, []);


    const fetchIncome = useCallback(async () => {
        let d = await fetchUserIncome(userId);

        setDirectIncome(d.sumDirectPaidEvents)
        setLevelIncome(d.sumTreePayoutEvents)
    }, [userId]);


    const fetchPanelDetails = useCallback(async () => {
        console.log("user id", userId)
        let actDiv = document.getElementById('activitiesView');
        actDiv.classList.add('loaderData');



        // let getAchivement = await getAchivementTransactions(library, userId);
        // setAchievementAmount(getAchivement);

        // setAchievementThailand(getAchivement * 100 / 2500);
        // setAchievementMagic(getAchivement * 100 / 4500);

        let userAddress;
        userAddress = await planC.getAddressById(userId);

        const userInfoResponse = await planC.userInfos(userAddress);
        const storedAmount = await planC.storedBalances(userAddress);

        setCachedAmount(parseFloat(ethers.utils.formatEther(storedAmount)))

        // console.log("u", userInfoResponse);
        if (userInfoResponse.joined == false) {
            toast.error("This user id does not exist");
            navigate("/");
        }

        const _totalInvested = calculateTotalInvested(userInfoResponse['levelBought'].toNumber())
        console.log("totalInvested", _totalInvested, userInfoResponse['levelBought'].toNumber())
        setTotalInvested(_totalInvested)

        const levelBought = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();
        const partners = ethers.BigNumber.from(userInfoResponse["partners"]).toNumber();
        const userTotalIncome = ethers.BigNumber.from(ethers.BigNumber.from(userInfoResponse["totalIncome"]).div(ethers.BigNumber.from("1000000000000000000"))).toNumber();

        setUserAddress(userAddress)
        setUserTotalIncome(userTotalIncome)

        let tMs = await planC.lastIDCount();
        setTotalMembers(ethers.BigNumber.from(tMs).toNumber());
        let totalInvestedBN = await planC.totalValueInvested();
        let allTotalValue = ethers.utils.formatEther(totalInvestedBN)
        setAllInvested(allTotalValue);

        if (levelBought > 0) {
            if (levelBought > 15) {
                setLevelBought(15)
            } else {
                setLevelBought(levelBought)
            }
        }
        setPartners(partners)

        const tempreinvestCounts = await getReinvestCounts(userAddress);
        // console.log("temp->",tempreinvestCounts[0])
        setReinvestCounts(tempreinvestCounts)
        let userWithLevels = tempidsArray;

        const ids = await planC.getAllLevelTrees(userAddress);
        const uniqueIds = [];
        for (let i = 0; i < 15; i++) {
            if (ids.tree) {
                ids.tree[i].forEach((response, index) => {
                    const id = ethers.BigNumber.from(response).toNumber();
                    uniqueIds[id] = id;
                    if (userWithLevels[i]) userWithLevels[index] = id;
                });
            } else {
                ids[i].forEach((response, index) => {
                    const id = ethers.BigNumber.from(response).toNumber();
                    uniqueIds[id] = id;
                    if (userWithLevels[i]) userWithLevels[i][index] = id;
                });
            }
        }

        let countP = 0;
        uniqueIds.forEach((t) => {
            countP++;
        });
        setIdsArray(userWithLevels);
        let teamSizeCheck = tempreinvestCounts[0];

        if (parseInt(userId) === 1) {
            teamSizeCheck = teamSizeCheck - 1;
        }
        let addOnTeamMemberCycle = 0;
        if (teamSizeCheck > 0) {
            addOnTeamMemberCycle = teamSizeCheck * 30;
        }

        if (parseInt(userId) === 1) {
            addOnTeamMemberCycle = addOnTeamMemberCycle + 1;
        }

        setMyTeamTotal(countP - 2 + addOnTeamMemberCycle)

        let getActivity = await getLast10TransactionsAndEvents(library);
        setActivity(getActivity)

        actDiv.classList.remove('loaderData');
    }, [planC, userId, location, tempidsArray])

    useEffect(() => {
        if (parseFloat(totalInvested) !== 0 && parseFloat(userTotalIncome) !== 0) {
            console.log(userTotalIncome, totalInvested)
            setProfitRatio((userTotalIncome / totalInvested) * 100)
        }
    }, [totalInvested, userTotalIncome])

    const counts = []
    async function getReinvestCounts(_user) {
        for (let i = 1; i <= 15; i++) {
            let count = await planC.userReInvestNumbers(_user, i);
            counts[i - 1] = parseInt(count);
        }
        return counts;
    }
    const navigate = useNavigate();

    const handleSlotClick = async (_userid, _level) => {
        if (userId !== 0) {
            navigate('/dashboard-user?user_id=' + userId + '&slot=' + _level)
        }
    }

    async function delayService(sec = 8000) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(true), sec)
        })
    }

    const handleBuyLevel = async (level) => {
        if (!account) {
            toast.error("Please connect your wallet");
            return;
        }

        if (userAddress !== account) {
            toast.error("You can't Buy other person's Slot, if its yours then connect your wallet with this ID");
            return;
        }

        if (levelBought + 1 < level) {
            // alert("You have to buy previous level first");
            toast.error("You have to buy previous level first.");
            throw new Error("You have to buy previous level first");
        }

        try {
            setTransaction(true);
            const allowance = await usdtInstance.allowance(account, planC.address)
            let allowanceParsed = ethers.utils.formatUnits(allowance, 18)
            // const ocAmount = await planC.getOCAmount(levelPrices[level])
            // let ocAmountParsed = ethers.utils.formatUnits(ocAmount, 18)

            // if (parseFloat(allowanceParsed) < parseFloat(ocAmountParsed)) {
            //     toast.error("USDT Allowance is low. Approve First")
            //     await approveOC()
            // }

            const buyLevelPromise = await planC.buyLevel(level);
            await toast.promise(
                buyLevelPromise.wait(),
                {
                    pending: 'Level Upgrade in process',
                    success: 'Level Upgrade Completed! 👌',
                    error: 'Level Upgrade failed '
                }
            )

            await delayService();
            fetchPanelDetails()
            setTransaction(false);
        } catch (error) {
            setTransaction(false);
            toast.error(`Level Upgrade failed - ${error.reason ? error.reason : (error.data ? error.data.message : error.message)}`)
            console.log(error)
        }
    }

    // const { signMessage } = useSignMessage()

    // if (!isSigned) {
    //     return (
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col-12 text-center">
    //                     <h3>Please sign the message to proceed</h3>
    //                     <button className="btn btn-primary" onClick={signMessage}>Sign</button>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
            <section className='loginMian rotateGradient'>
                <div className="container">
                    <div className="row">
                        <div className='col-12 col-md-12 order-1 order-md-0 mb-40'>
                            <div className='row'>
                                <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                    <div className='inrBox odd'>
                                        <i></i><i></i><span></span>
                                        <img src={partnersIcon} alt="partners icon" width="70" height="70" />
                                        <h3>{partners}</h3>
                                        <p>Direct Partners</p>
                                        {/* <CIcon icon={cilContact} /> */}
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                    <div className='inrBox even'>
                                        <i></i><i></i><span></span>
                                        <img src={unlockedIcon} alt="Unloack" width="70" height="70" />
                                        <h3>{levelBought}</h3>
                                        <p>Slots Unlocked</p>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                    <div className='inrBox odd'>
                                        <i></i><i></i><span></span>
                                        <img src={ratioIcon} alt="Ratio icon" width="70" height="70" />
                                        <h3>{profitRatio.toFixed(2)}%</h3>
                                        <p>Profit Ratio</p>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                    <div className='inrBox even'>
                                        <i></i><i></i><span></span>
                                        <img src={incomeIcon} alt="Profits" width="70" height="70" />
                                        <h3>$ {userTotalIncome}</h3>
                                        <p>Profits</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row memberBox'>
                        <div className='col-12 col-lg-4 col-md-6 order-2 order-md-1 order-lg-0'>
                            <div className='memberBoxInr'>
                                <div className='row memberData'>
                                    <div className='col-12 mb-2'>
                                        <div className='memberBoxinr'>
                                            <div>
                                                <p>Total Direct <span>Income</span></p>
                                                <h3>{directIncome.toFixed(2)}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='memberBoxinr'>
                                            <div>
                                                <p>Total Level <span>Income</span></p>
                                                <h3>{levelIncome.toFixed(2)}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='memberBoxinr'>
                                            <div>
                                                <p>My Team <span>Total</span></p>
                                                <h3>{myTeamTotal}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4 order-1 order-md-2 order-lg-1'>
                            <div className='mainnetInr'>
                                <div className="memberBox">
                                    <div className="innermbrbx">
                                        <h3>Not a member yet? Sign up with this upline</h3>
                                        <strong>Personal link :</strong>
                                        <CopyToClipboard text={`${BASE_URL}register?referral=` + (userId)}>
                                            <button className='referralBtn addressCopyBtn' onClick={() => { copySuccessfully(); }}>{BASE_URL}register?referral={(userId)}<img src={copyIcon} alt="Copy Icon" width="18" height="18" /></button>
                                        </CopyToClipboard>
                                        {/* <CopyToClipboard text={`${BASE_URL}register?referral=` + (data['referral_link'] && data['referral_link'] != '' ? data['referral_link'] : userId)}>
                                        <button className='referralBtn addressCopyBtn' onClick={() => { copySuccessfully(); }}>{BASE_URL}register?referral={(data['referral_link'] && data['referral_link'] != '' ? data['referral_link'] : userId)}<img src={copyIcon} alt="Copy Icon" width="18" height="18" /></button>
                                    </CopyToClipboard> */}
                                    </div>
                                    <div className='autoLogin'>
                                        <a href={`${BASE_URL}register?referral=` + (userId)}>
                                            <button className='am_btn'><i></i><i></i><span>Sign Up</span></button>
                                        </a>
                                    </div>
                                    <div className='cachedFundsBox'>
                                        <p>Stored Funds : <code>{cachedAmount} USDT</code><b data-bs-toggle="tooltip" data-bs-html="true" title="">ⓘ</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4 col-md-6 order-0 order-md-0 order-lg-2'>
                            <div className='profilebox'>
                                <div className='imgboxprof'>
                                    <img className="prfllgo" src={logofav} style={{ borderRadius: "50%" }} alt="ox profile" width="60" height="60" />
                                    {/* <img className="prfllgo" src={data['image'] && data['image'] !== "undefined" ? `${data['image']}` : logofav} style={{borderRadius: "50%"}} alt="ox profile" width="60" height="60" /> */}
                                </div>
                                <h2>ID #{userId}</h2>
                                {/* { (account && userAddress.toLowerCase() === account.toLowerCase()) && <a href={profileURL} target="_blank" className='editProfileBtn'>Edit Profile</a> } */}
                                {/* {data['name'] && <h3>{data['name']}</h3> } */}
                                <h3>Rank: <b>{names[levelBought - 1]}</b></h3>
                                <CopyToClipboard text={userAddress}>
                                    <p>{userAddress.substr(0, 4)}...{userAddress.substr(-4)}
                                        <svg onClick={() => { copySuccessfully(); }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon" role="img"><polygon fill="var(--ci-primary-color, currentColor)" points="408 432 376 432 376 464 112 464 112 136 144 136 144 104 80 104 80 496 408 496 408 432" className="ci-primary"></polygon><path fill="var(--ci-primary-color, currentColor)" d="M176,16V400H496V153.373L358.627,16ZM464,368H208V48H312V200H464Zm0-200H344V48h1.372L464,166.627Z" className="ci-primary"></path></svg>
                                    </p>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                    {/* <div className='row justify-content-center'>
                        <div className='col-6 col-sm-5 col-lg-4 col-xl-4 mb-4'>
                            <div className='inrBox odd'>
                                <i></i><i></i><span></span>
                                <strong className='tooltipPro' data-bs-toggle1="tooltip" data-bs-html="true" title="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" aria-labelledby="title" id="info"><path fill="[Color-Muted]" d="M19.3 10.1c-.5.5-1.1.7-1.8.7s-1.3-.2-1.8-.7c-.5-.5-.8-1-.8-1.7s.3-1.2.8-1.7c.4-.5 1-.7 1.8-.7.7 0 1.3.2 1.8.7s.7 1 .7 1.7-.2 1.2-.7 1.7zm.1 14.9c-.9.3-1.5.6-2.1.8-.5.2-1.1.3-1.8.3-1 0-1.9-.2-2.4-.8-.6-.5-.9-1.2-.9-1.9 0-.3 0-.6.1-.9 0-.3.1-.7.2-1.1l1.1-3.8c.1-.4.2-.7.2-1 .1-.3.1-.6.1-.9 0-.5-.1-.8-.3-1s-.6-.3-1.2-.3c-.3 0-.6 0-.8.1-.3.1-.5.2-.7.3l.3-1.2c.7-.3 1.4-.5 2-.7s1.2-.3 1.8-.3c1 0 1.8.2 2.4.7s.8 1.1.8 1.9c0 .2 0 .5-.1.9 0 .4-.1.8-.2 1.1l-.9 3.7c-.1.3-.2.7-.2 1-.1.4-.1.7-.1.9 0 .5.1.8.3 1s.6.3 1.2.3c.3 0 .6 0 .9-.1s.6-.2.7-.2l-.4 1.2zM32 16c0 8.8-7.2 16-16 16S0 24.8 0 16 7.2 0 16 0s16 7.2 16 16zM16 2C8.3 2 2 8.3 2 16s6.3 14 14 14 14-6.3 14-14S23.7 2 16 2z" className="icons-background"></path></svg>
                                </strong>
                                <img className='magicImg' src={unlockThailand} alt="unlockThailand icon" width="70" height="70" />
                                <h3>({achievementAmount}/2500)</h3>
                                <p>Unlock Thailand</p>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={achievementThailand} aria-valuemin="0" aria-valuemax="100" style={{ width: `${achievementThailand}%` }}></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-sm-5 col-lg-4 col-xl-4 mb-4'>
                            <div className='inrBox even'>
                                <i></i><i></i><span></span>
                                <strong className='tooltipPro' data-bs-toggle2="tooltip" data-bs-html="true" title="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" aria-labelledby="title" id="info"><path fill="[Color-Muted]" d="M19.3 10.1c-.5.5-1.1.7-1.8.7s-1.3-.2-1.8-.7c-.5-.5-.8-1-.8-1.7s.3-1.2.8-1.7c.4-.5 1-.7 1.8-.7.7 0 1.3.2 1.8.7s.7 1 .7 1.7-.2 1.2-.7 1.7zm.1 14.9c-.9.3-1.5.6-2.1.8-.5.2-1.1.3-1.8.3-1 0-1.9-.2-2.4-.8-.6-.5-.9-1.2-.9-1.9 0-.3 0-.6.1-.9 0-.3.1-.7.2-1.1l1.1-3.8c.1-.4.2-.7.2-1 .1-.3.1-.6.1-.9 0-.5-.1-.8-.3-1s-.6-.3-1.2-.3c-.3 0-.6 0-.8.1-.3.1-.5.2-.7.3l.3-1.2c.7-.3 1.4-.5 2-.7s1.2-.3 1.8-.3c1 0 1.8.2 2.4.7s.8 1.1.8 1.9c0 .2 0 .5-.1.9 0 .4-.1.8-.2 1.1l-.9 3.7c-.1.3-.2.7-.2 1-.1.4-.1.7-.1.9 0 .5.1.8.3 1s.6.3 1.2.3c.3 0 .6 0 .9-.1s.6-.2.7-.2l-.4 1.2zM32 16c0 8.8-7.2 16-16 16S0 24.8 0 16 7.2 0 16 0s16 7.2 16 16zM16 2C8.3 2 2 8.3 2 16s6.3 14 14 14 14-6.3 14-14S23.7 2 16 2z" className="icons-background"></path></svg>
                                </strong>
                                <img className='magicImg' src={doubleMagic} alt="Double icon" width="70" height="70" />
                                <h3>({achievementAmount}/4500)</h3>
                                <p>Double the Magic</p>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={achievementMagic} aria-valuemin="0" aria-valuemax="100" style={{ width: `${achievementMagic}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <section className="treeAeromatix">
                <div className="container">
                    <div className='titleBox'>
                        <h2>ApeCommunity NFT</h2>
                        <p>ApeCommunity NFT ({levelBought >= 15 ? 15 : levelBought} out of 15 levels)</p>
                    </div>
                    <div className="row rowtreebox">
                        {idsArray.map((ids, index) => {
                            const isActive = (index + 1) <= levelBought;
                            return <Slot cachedAmount={cachedAmount} ocPrice={ocPrice} levelPrices={levelPrices} key={index} reinvestCounts={reinvestCounts} userId={userId} ids={ids} index={index} isActive={isActive} levelBought={levelBought} onSlotClick={handleSlotClick} onBuyLevel={handleBuyLevel}></Slot>
                        })}
                    </div>
                </div>
            </section>
            <section className='accountPreviewMain mt-4 rotateGradient'>
                <div className="container">
                    <div className='row tablemainnet align-items-start'>
                        <div className='col-12 col-lg-7'>
                            <div className="titleBox">
                                <h2>Recent Activities</h2>
                            </div>
                            <div className='tableInr' id='activitiesView'>
                                <div className='table-responsive dashboardPg'>
                                    <table className="table table-striped-columns">
                                        {/* <thead>
                                            <tr>
                                                <th width="10%"></th>
                                                <th width="20%"></th>
                                                <th width="35%"></th>
                                                <th width="35%"></th>
                                            </tr>
                                        </thead> */}
                                        <tbody>
                                            {activity.map((a, index) => {
                                                return (a.eventsName == "Registration" ? <tr key={index}>
                                                    <td><img src={logofav} alt="ETH" width="20" height="20" /></td>
                                                    <td><a href="#!">ID {parseInt(a.userId)}</a></td>
                                                    <td className='txtinf'>NEW USER <p>JOINED</p></td>
                                                    <td className='linkshr text-end'>
                                                        <a href={BASE_BSC_SCAN_URLS + "/tx/" + a.txId} target="blank"><svg className="w-6 h-6" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"></path></svg></a>
                                                        <span>{a.date}</span></td>
                                                </tr> : <tr key={index}>
                                                    <td><img src={logofav} alt="ETH" width="20" height="20" /></td>
                                                    <td><a href="#!">ID {parseInt(a.userId)}</a></td>
                                                    <td className='txtinf'><span>+</span>{ethers.utils.formatEther(a.amount)} USD<span>in</span><p>USDT</p></td>
                                                    <td className='linkshr text-end'>
                                                        <a href={BASE_BSC_SCAN_URLS + "/tx/" + a.txId} target="blank"><svg className="w-6 h-6" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"></path></svg></a>
                                                        <span>{a.date}</span></td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-5'>
                            <div className="titleBox">
                                <h2>ApeCommunity NFT Contracts</h2>
                            </div>
                            <div className='mainnetInr mb-4'>
                                <div className='row linkBox'>
                                    <strong>ApeCommunity NFT</strong><span>{OxNFTContract.substr(0, 4)}...{OxNFTContract.substr(-4)}
                                        <a href={"https://polygonscan.com/address/" + OxNFTContract} target="_blank"><svg className="h-18px w-18px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.314 3.813a3.446 3.446 0 1 1 4.873 4.874l-3.331 3.331a3.418 3.418 0 0 1-4.88-.001.5.5 0 0 1 .715-.7 2.417 2.417 0 0 0 3.452 0l.004-.004L15.48 7.98a2.446 2.446 0 1 0-3.46-3.46l-.416.417a.5.5 0 1 1-.707-.707l.417-.417Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.26 7.225a3.417 3.417 0 0 1 3.765.758.5.5 0 0 1-.715.7 2.417 2.417 0 0 0-3.452 0l-.003.004L4.52 12.02a2.446 2.446 0 0 0 3.46 3.46l.416-.417a.5.5 0 1 1 .708.707l-.417.417a3.446 3.446 0 1 1-4.874-4.874l3.332-3.332a3.417 3.417 0 0 1 1.115-.756Z" fill="#fff"></path></svg></a>
                                    </span>
                                </div>
                                <div className='row linkBox'>
                                    <strong>USDT</strong><span>{usdtContract.substr(0, 4)}...{usdtContract.substr(-4)}
                                        <a href={"https://polygonscan.com/token/" + usdtContract} target="_blank"><svg className="h-18px w-18px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.314 3.813a3.446 3.446 0 1 1 4.873 4.874l-3.331 3.331a3.418 3.418 0 0 1-4.88-.001.5.5 0 0 1 .715-.7 2.417 2.417 0 0 0 3.452 0l.004-.004L15.48 7.98a2.446 2.446 0 1 0-3.46-3.46l-.416.417a.5.5 0 1 1-.707-.707l.417-.417Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.26 7.225a3.417 3.417 0 0 1 3.765.758.5.5 0 0 1-.715.7 2.417 2.417 0 0 0-3.452 0l-.003.004L4.52 12.02a2.446 2.446 0 0 0 3.46 3.46l.416-.417a.5.5 0 1 1 .708.707l-.417.417a3.446 3.446 0 1 1-4.874-4.874l3.332-3.332a3.417 3.417 0 0 1 1.115-.756Z" fill="#fff"></path></svg></a>
                                    </span>
                                </div>
                                <div className='row linkBox'>
                                    <strong>Members total</strong><span>
                                        {totalMembers}
                                    </span>
                                </div>
                                <div className='row linkBoxBtm'>
                                    <p>Turnover, USD</p><span>{allInvested}</span>
                                </div>
                            </div>
                            {/* <div className='row dashpgMbr'>
                            <div className='col-12 col-md-6'>
                                <div className='mainnetInr'>
                                    <h3>Members total</h3>
                                    <strong>{totalMembers}</strong>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mainnetInr'>
                                    <h3>Members received, USD</h3>
                                    <strong>{allInvested}</strong>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard