import React, { useCallback } from 'react'
import './Banner.css'
import { useNavigate } from 'react-router';
import useActiveWeb3React from '../../hooks/useWeb3';
import toast, { Toaster } from 'react-hot-toast';
import { useStakeContract } from '../../hooks/useContract';

const Banner = () => {

    const navigate = useNavigate();
    const { library, chainId, account } = useActiveWeb3React()
    const planC = useStakeContract();

    const makeAutoLogin = useCallback(async () => {
        if (!account) {
            toast.error("Connect wallet!")
            return
        }
        try {
            const userInfo = await planC.userInfos(account)
            // console.log(userInfo)
            if (userInfo.joined) {
                navigate("/dashboard?user_id=" + parseInt(userInfo.id))
            } else {
                navigate("/register")
            }
        } catch (Err) {
            console.log(Err)
        }
    }, [account, planC])

    return (
        <>
            <section className="sf_banner rotateGradient">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-12 text-center order-1 order-lg-0">
                            <div className='contentinfolt'>
                                <h1>Discover the future of digital ownership</h1>
                                <p>Experience a paradigm shift in digital ownership on our NFT platform.</p>
                                <button onClick={makeAutoLogin} className='am_btn'><i></i><i></i><span>Auto-Login »</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner