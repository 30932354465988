import { OxNFTContract, graphURL } from '../config';
import abi from '../config/abis/AeromatiX.json';
import axios from 'axios';
import { ethers } from 'ethers'

function getDate(x) {
    const myDate = new Date(x * 1000);
    return myDate;
}


async function getAchivementTransactions(library, userId) {

    try {

        const provider = library
      
          const options = {
              method: 'POST',
              url: graphURL,
              headers: {
                  'content-type': 'application/json'
              },
              data: {
                  query: `{      
                      directPaidEvents(where: {to: ${userId}, blockTimestamp_gt: 1711909801}) {
                          id
                          from
                          amount
                          blockNumber
                          transactionHash
                      }
                  }`
              }
          };
      
          const response = await axios.request(options);
        //   console.debug(response)
          const res = response.data;
          const directPaidEvents = res.data.directPaidEvents || [];

        //   console.debug("transactions", achivementTransactions)
          let achivementTotal = parseFloat(0);
            for (const tx of directPaidEvents) {
                achivementTotal += parseFloat(ethers.utils.formatEther(tx.amount));
            }

        return achivementTotal;
    } catch(err) {
        console.debug("err", err)
        return []
    }
}

export default getAchivementTransactions;
