import React from 'react'
import './Home.css'
import logofav from '../../Assets/images/iconlogo.webp'

const Home = () => {
    return (
        <>
            <section className='homePageMain'>
                <div className='titleBox'>
                    <h2>Welcome to ApeCommunity NFT</h2>
                </div>
                <div className='counterBox'>
                    <h1><span>$</span>1751.69</h1>
                    <p>+$10.36</p>
                    <div className='btnGrpBox'>
                        <button className='am_btn'>Connect AMX</button>
                        <button className='am_btn'>Join Now</button>
                    </div>
                </div>
                <div className='tableInr'>
                    <h3>My Tokens</h3>
                    <div className='table-responsive'>
                        <table className="table table-striped-columns">
                            <thead>
                                <tr>
                                    <th width="50%"></th>
                                    <th width="50%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img src={logofav} alt="ETH" width="20" height="20" />ETM</td>
                                    <td className='linkshr text-end'>865.65</td>
                                </tr>
                                <tr>
                                    <td><img src={logofav} alt="ETH" width="20" height="20" />ETM</td>
                                    <td className='linkshr text-end'>865.65</td>
                                </tr>
                                <tr>
                                    <td><img src={logofav} alt="ETH" width="20" height="20" />ETM</td>
                                    <td className='linkshr text-end'>865.65</td>
                                </tr>
                                <tr>
                                    <td><img src={logofav} alt="ETH" width="20" height="20" />ETM</td>
                                    <td className='linkshr text-end'>865.65</td>
                                </tr>
                                <tr>
                                    <td><img src={logofav} alt="ETH" width="20" height="20" />ETM</td>
                                    <td className='linkshr text-end'>865.65</td>
                                </tr>
                                <tr>
                                    <td><img src={logofav} alt="ETH" width="20" height="20" />ETM</td>
                                    <td className='linkshr text-end'>865.65</td>
                                </tr>
                                <tr>
                                    <td><img src={logofav} alt="ETH" width="20" height="20" />ETM</td>
                                    <td className='linkshr text-end'>865.65</td>
                                </tr>
                                <tr>
                                    <td><img src={logofav} alt="ETH" width="20" height="20" />ETM</td>
                                    <td className='linkshr text-end'>865.65</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home