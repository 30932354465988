import React, { useState, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import 'react-use-smooth-scroll/dist/index.css'
import Sidebar from '../Components/Sidebar/Sidebar'

const Layout = () => {
    const location = useLocation();
    const hideSidebar = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/';

    return (
        <>
            <Header />
            {hideSidebar && <Outlet />}
            {!hideSidebar && <div className="mainBoxWrap"><div className="row"><Sidebar /><div className="rightBoxMain"><Outlet /></div></div></div>}
            <Footer />

        </>
    )
}

export default Layout